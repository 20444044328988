import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { useParams } from 'react-router-dom';
import PassSenderAction from "../actions/PassSenderAction";
import AdminFormContainer from "../Admin/AdminFormContainer.styles";
import { ToastContainer, toast } from 'react-toastify';
import Button from "../components/buttons/Button.styles";
import moment from "moment-timezone";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setIsTimeOut } from "../redux-actions/adminActions";

alertify.defaults.glossary.ok = 'Tak';
alertify.defaults.glossary.cancel = 'Nie';

const UserLink = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { hash } = useParams();
    const [obj, setObj] = useState({});
    const [textAreaValue, setTextAreaValue] = useState('');
    const [isCurrentDateGreater, setIsCurrentDateGreater] = useState(true);
    const [isDelete, setIsDelete] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isOneTime, setIsOneTime] = useState(false);
    const [readOnePassword, setReadOnePassword] = useState('');
    const textareaRef = useRef(null);
    const [showContent, setShowContent] = useState(false);
    const [isTimeOut, setIsTimeOut] = useState(false);
    const [isHoverButton, setIsHoverButton] = useState(false);
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setIsTimeOut(true);
            clearInterval(intervalRef.current);
        }, 60000);

        let countdown = 60;
        intervalRef.current = setInterval(() => {
            //console.log(`Pozostały czas: ${countdown} sekund`);
            countdown -= 1;
        }, 1000);
    };

    useEffect(() => {
        PassSenderAction.getPass(hash)
            .then(res => {
                if (res.status === 200) {
                    const value = res.data;
                    setObj(value);
                    if (value.oneTime && value.readPassword === '') {
                        setIsOneTime(true);
                    }
                    if (value && value.readPassword !== '') {
                        setIsPassword(true);
                    }
                    // setTimeout(() => {
                    //     setIsTimeOut(true);                            
                    // }, 20000);  

                    resetTimeout();
                }
            })

    }, []);



    useEffect(() => {
        if (Object.keys(obj).length !== 0) {
            const textarea = textareaRef.current;
            if (textarea) {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
                textarea.style.maxHeight = `${window.innerHeight / 2}px`;
            }
        }

    }, [textAreaValue]);

    useEffect(() => {
        if (Object.keys(obj).length !== 0) {
            setTextAreaValue(obj.value);
        } else {
            setTextAreaValue('');
        }


    }, [obj]);


    useEffect(() => {
        if (isOneTime) {
            alertify.confirm(
                "UWAGA",
                "Ten wpis można odczytać tylko jeden raz. <br/> Czy chcesz wyświetlić go teraz?",
                () => {
                    setShowContent(true);
                    setIsOneTime(false);
                    setIsHoverButton(true);
                    PassSenderAction.deletePass(hash);

                },
                () => {
                    setShowContent(false);
                }
            );
        } else {
            setShowContent(true);
        }
    }, [isOneTime]);


    useEffect(() => {
        const savedDate = new Date(obj.endDate);

        if (!isNaN(savedDate)) {
            PassSenderAction.checkDate(hash)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data) {
                            setIsCurrentDateGreater(false);
                        } else {
                            setIsCurrentDateGreater(true);
                        }
                    }
                })
        }

    }, [obj.endDate]);


    const copyToClipboard = (e) => {
        if (obj) {
            try {                
                textareaRef.current.select();
                document.execCommand("copy");
                toast.success('Skopiowano do schowka');
            } catch (e) {
                toast.error('Nie udało się skopiować');
            }
        }
    };

    const deleteHash = () => {
        alertify.confirm(
            "PassSender",
            "Czy na pewno chcesz usunąć ten wpis? <br/> Ponowne jego wyświetlenie będzie niemożliwe.",
            () => {
                PassSenderAction.deletePass(hash)
                    .then(res => {
                        if (res.status === 200) {
                            setIsDelete(true);
                            toast.success('Wpis został usunięty');
                        }
                    })
                    .catch((e) => {
                        setIsDelete(false);
                        toast.error('Wpis nie został usunięty');
                    })
            },
            () => { }
        );
    }

    const handleChangeReadOnePassword = (e) => {
        const { value } = e.target;
        setReadOnePassword(value.trimEnd());
    }

    const checkPassword = () => {
        PassSenderAction.checkPassword(hash, readOnePassword)
            .then(res => {
                if (res.status === 200) {
                    const value = res.data;
                    if (value) {
                        setIsPassword(false);
                        if (obj.oneTime) {
                            setIsOneTime(true);
                        }
                    } else {
                        toast.error('Błędne hasło');
                    }
                }
            })
            .catch(e => {
                if (readOnePassword === "") {
                    toast.error('Błędne hasło');
                } else {
                    toast.error('Coś poszło nie tak');
                }

            })
    }

    useEffect(() => {        
        const events = ['click', 'keypress', 'scroll', 'touchstart'];
        const resetAndTrackTimeout = () => resetTimeout();

        events.forEach(event => window.addEventListener(event, resetAndTrackTimeout));

        return () => {
            events.forEach(event => window.removeEventListener(event, resetAndTrackTimeout));
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if(isTimeOut){
            history.push("/timeOut");
        }
    }, [isTimeOut]);


    return (
        <>
            <Navbar user={true} />
            <ToastContainer
                theme="dark"
            />
            <div style={{ position: "fixed", top: "80px", width: "100%", height: "calc(100% - 80px)", overflow: "auto" }}>
                <AdminFormContainer>
                    {!isPassword ?
                            isDelete ?
                                <h3 className="color-text">Wpis został usunięty.</h3>
                                : isCurrentDateGreater && textAreaValue ?
                                    showContent && !isOneTime ?
                                        <>
                                            <div className="input-copy-container">
                                                <textarea
                                                    ref={textareaRef}
                                                    readOnly
                                                    value={textAreaValue}
                                                    className="input-link"
                                                    style={{ minHeight: "100px" }}
                                                />
                                                <div className="button-copy">
                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            {!isHoverButton ? <span style={{ color: "red", fontSize: "20px" }}>{`Wpis zostanie automatycznie usunięty po: ${moment(obj.endDate).tz('Europe/Warsaw').format("DD.MM.YYYY, HH:mm")}.`}</span> : <span />}
                                                            <Button className="font-size-button" width={81} onClick={(e) => copyToClipboard(e)}>Kopiuj</Button>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            {!isHoverButton ? <Button className="font-size-button" style={{ marginTop: "5px" }} width={81} onClick={() => deleteHash()}>Usuń</Button> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    :
                                    <div>
                                        <h3 className="color-text">Brak wpisu.</h3>
                                    </div>
                            :
                            <div style={{ width: "317px" }}>
                                <span className="color-text" style={{ fontSize: "20px" }}>Podaj hasło: </span>
                                <input className="input-readOne-password" value={readOnePassword} onChange={(e) => handleChangeReadOnePassword(e)} />
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                                    <Button className="font-size-button" width={81} onClick={() => checkPassword()}>Dalej</Button>
                                </div>
                            </div>}

                </AdminFormContainer>
            </div>

        </>
    )
}

export default UserLink;