import styled from 'styled-components';

const DefaultButtonContainer = styled.button`
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: var(--login-button-text-color);
    cursor: pointer;
    font-size: 20px;
    padding: 0.25rem 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    transition: box-shadow 0.25s ease-in;
    background-color: var(--login-button-background);

    .login-btn-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        border: 1px solid var(--login-button-hover);
        box-shadow: 0px 0px 0.5rem 0.125rem var(--login-button-shadow);
        color: var(--login-button-text-hover);
        transition: color 0.25s ease-in;
    }
    
    &::before {
        content: '';
        position: absolute;
        top: -5%;
        left: -175%;
        width: 150%;
        height: 110%;
        z-index: -1;
        background-color: #000;
        transition: 0.25s ease-in;
        transform: skewX(35deg);
    }

    &:hover::before {
        left: -25%;
    }

    > span { 
        opacity: ${({ isFetching }) => isFetching ? 0 : 1};
    }
`

export default DefaultButtonContainer;