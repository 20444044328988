import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import ButtonBorder from "../components/buttons/ButtonBorder.styles";

const ModalPassword = ({
  show,
  setShow,
  setText
}) => {
  const [rangeValue, setRangeValue] = useState(16);
  const [characters, setCharacters] = useState([{name: 'ABC', value: true}, {name: 'abc', value: true}, {name: '123', value: true}, {name: '#$&', value: true}]);
  

  const handleRangeChange = (e) => {
    const { value } = e.target;
    setRangeValue(value);
  }

  const incrementRange = () => {
    if (rangeValue < 32) {      
      setRangeValue(parseInt(rangeValue) + 1);
    }
  };

  const decrementRange = () => {
    if (rangeValue > 8) {
      setRangeValue(parseInt(rangeValue) - 1);
    }
  };

  const handleCheckboxChange = (index) => {
    const newCharacters = [...characters];
    newCharacters[index].value = !newCharacters[index].value;
    setCharacters(newCharacters);
  };


  const generatePassword = (length) => {
    const upperCase = characters.find(item => item.name === 'ABC' && item.value);
    const lowerCase = characters.find(item => item.name === 'abc' && item.value);
    const numbers = characters.find(item => item.name === '123' && item.value);
    const specialChars = characters.find(item => item.name === '#$&' && item.value);

    const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lower = 'abcdefghijklmnopqrstuvwxyz';
    const num = '0123456789';
    const special = '!@#$%^&*()[]{}<>/?';

    let validCharacters = '';
    if (upperCase) validCharacters += upper;
    if (lowerCase) validCharacters += lower;
    if (numbers) validCharacters += num;
    if (specialChars) validCharacters += special;

    if (validCharacters.length === 0) {
      return ''; // Return empty string if no characters are selected
    }

    // Ensure password contains at least one character from each selected category
    let password = '';
    if (upperCase) password += upper[Math.floor(Math.random() * upper.length)];
    if (lowerCase) password += lower[Math.floor(Math.random() * lower.length)];
    if (numbers) password += num[Math.floor(Math.random() * num.length)];
    if (specialChars) password += special[Math.floor(Math.random() * special.length)];

    // Fill the rest of the password with random characters from validCharacters
    for (let i = password.length; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * validCharacters.length);
      password += validCharacters[randomIndex];
    }

    // Shuffle the password to ensure randomness
    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    setText(password);
    closeModal();
  };

  const closeModal = () => {
    setShow(false);
    setRangeValue(16);
    setCharacters([{name: 'ABC', value: true}, {name: 'abc', value: true}, {name: '123', value: true}, {name: '#$&', value: true}]);
  }


  return (
    <div
      className={
        show ? "modal display-block modal-scroll" : "modal display-none"
      }
    >
      <div className="modal-dialog" role="document">
        <div className="universal-modal-container">
          <div className="universal-modal-header" style={{borderBottom: "1px solid #7f7f7f", paddingBottom: "5px"}}>
            <div className="universal-modal-header-exit-button">
              <div className="modal-heder">
              <span className="modal-heder-text">Generuj hasło</span>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => closeModal()}
                style={{ cursor: "pointer" }}
              />
              </div>
            </div>
          </div>
          <div className="universal-modal-body">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{fontSize: "20px"}}>{`Długość hasła: ${rangeValue}`}</span>
              <div style={{display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                  icon={faCircleMinus}
                  style={{cursor: "pointer"}}
                  onClick={() => decrementRange()}
                />                
                <input
                  style={{ height: "20px" }}
                  className="slider"
                  type="range"
                  min={8}
                  max={32}
                  value={rangeValue}
                  onChange={(e) => handleRangeChange(e)}
                />
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{cursor: "pointer"}}
                  onClick={() => incrementRange()}
                />
              </div>
              <div style={{marginTop: "10px"}}>
                <span style={{fontSize: "20px"}}>Używane znaki:</span>
                {characters && characters.map((item, index) => (
                  <div style={{display: "flex", marginBottom: "5px"}} key={index}>
                  <input type="checkbox" checked={item.value} onChange={() => handleCheckboxChange(index)}/>
                  <label style={{fontSize: "20px"}}>{item.name}</label>
                  </div>

                ))}
              </div>
              <div style={{display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
                <ButtonBorder onClick={() => generatePassword(rangeValue)}>Generuj</ButtonBorder>
                <ButtonBorder style={{marginLeft: "10px"}} onClick={() => closeModal()}>Anuluj</ButtonBorder>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalPassword;