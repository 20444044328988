import { SET_IS_HASH, SET_IS_CLEAR, SET_COMPANY } from "../actions/types";

export const setIsHash = (value) => {
    
    const payload = {
        value
    }

    return {
        type: SET_IS_HASH,
        payload
    }
}

export const setIsClear = (value) => {
    
    const payload = {
        value
    }

    return {
        type: SET_IS_CLEAR,
        payload
    }
} 

export const setCompany = (value) => {
    
    const payload = {
        value
    }

    return {
        type: SET_COMPANY,
        payload
    }
} 
