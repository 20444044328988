import axios from "axios";

const URL = '/passSender/api';

class PassSenderAction {
    savePass = (value) => axios.post(URL + `/save/pass`, value);
    getPass = (hash) => axios.get(URL + `/get/pass/${hash}`);
    getLocalDateTime = () => axios.get(URL + `/get/localDateTime`);
    deletePass = (hash) => axios.delete(URL + `/delete/pass/${hash}`);
    checkDate = (hash) => axios.get(URL + `/get/check/date/${hash}`); 
    checkPassword = (hash, readOnePassword) => axios.get(URL + `/check/password/${hash}/${readOnePassword}`); 
    
    

    


    
}

export default new PassSenderAction();