import styled from 'styled-components';

const NavbarContainer = styled.nav `
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 2.6rem;
padding: 0 0.5rem;
display: flex;
align-items: center;
justify-content: space-between; 
border-bottom: 2px solid #7f7f7f;
background: var(--navbar-background);
z-index: 10;

.text-navbar {
    margin-left: 15px;
    color: var(--text-navbar);
    
}
.button-container {
    display: flex;
}

.font-size-button {
    font-size: 15px;
}

img {
    margin-right: auto; /* Align the logo to the left */
}

button {
    margin-left: auto; /* Align the button to the right */
}

@media (max-width: 768px) {
    padding: 0 1rem;
    img {
        height: 25px;
        width: 25px;
    }

    button {
        padding: 0.3rem 0.5rem;        
    }
}

`

export const LoginButton = styled.button`
    background-color: var(--login-button); 
    border: none; 
    color: white; 
    padding: 0.3rem 0.5rem; 
    text-align: center; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 1rem; 
    margin: 0 1rem; 
    cursor: pointer; 
    border-radius: 0.25rem; 
    transition: background-color 0.3s ease; 
    font-size: 0.875rem;
    

    &:hover {        
        background-color: var(--login-button-hover-active);
       
    }

    &:active {
        background-color: var(--login-button-hover-active); 
    }

    @media (max-width: 768px) {
        padding: 0.3rem 0.5rem;
        font-size: 0.875rem; 
        /*margin: 0 1rem;*/
        margin-right: 50px;
    }
`;

export default NavbarContainer;