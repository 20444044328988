import React from 'react'
import DefaultSpinnerContainer from './DefaultSpinner.styles';

const DefaultSpinner = ({
    className = '',
    height = 30,
    width = 30
}) => {
    return (
        <DefaultSpinnerContainer
            className={`${className}`}
            height={height}
            width={width}
        >
            <div className='default-spinner'>
                <div className='default-spinner default-spinner-inner'></div>
            </div>
        </DefaultSpinnerContainer>
    )
}

export default DefaultSpinner