import styled from 'styled-components';

export const ButtonBorder = styled.button`    
    background-color: transparent; 
    border: 1px solid var(--button-border); 
    color: var(--button-border); 
    padding: 0.3rem 0.5rem; 
    text-align: center; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 1rem; 
    margin-right: ${({ marginRight }) => `${marginRight}px`};
    width: ${({ width }) => `${width}px`};
     
    cursor: pointer; 
    border-radius: 0.25rem; 
    transition: background-color 0.3s ease; 
    font-size: 0.875rem;

    &:hover {
        background-color: var(--button-hover-active); 
    }

    &:active {
        background-color: var(--button-hover-active); 
    }

    @media (max-width: 768px) {
        padding: 0.3rem 0.5rem;
        font-size: 0.875rem; 
        
    }
`;

export default ButtonBorder;