import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import jwt_decode from "jwt-decode";
import { logout } from "./actions/SecurityActions";
const initialState = {};

const checkTokenExpirationMiddleware = store => next => action => {
  const jwtToken = localStorage.jwtToken;
  if (jwtToken) {
    const decoded_jwtToken = jwt_decode(jwtToken);
    const currentTime = Date.now() / 1000;
    if (decoded_jwtToken.exp < currentTime) {
      next(action);
      store.dispatch(logout());
      window.location.href = "/";
    }
  }
  next(action);
};
const middleware = [thunk, checkTokenExpirationMiddleware];

let store;
const ReactReduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
  if ((window.navigator.userAgent.includes("Firefox") || 
      window.navigator.userAgent.includes("Chrome")) && 
      ReactReduxDevTools) {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ReactReduxDevTools
    )
  );
} else {
  store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  );
}

export default store;
