import { combineReducers } from "redux";
import securityReducer from "./securityReducer";
import adminReducer from "./adminReducer";



export default combineReducers({
    security: securityReducer,
    admin: adminReducer
});
