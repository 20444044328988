import styled from 'styled-components';

const DefaultSpinnerContainer = styled.div`
    height: ${({ height }) => `${height}px`};
    width: ${({ width }) => `${width}px`};
   
    .default-spinner {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        border: 5px solid transparent;
        border-top-color: #7f7f7f;
        border-bottom-color: #7f7f7f;
        animation: rotate-spinner 5s linear infinite;

        .default-spinner-inner {
            border-top-color: #7f7f7f;
            border-bottom-color: #7f7f7f;
            animation: rotate-spinner 3s infinite;
        }
        
        @keyframes rotate-spinner {
            0% {
                transform: scale(1) rotate(360deg);
            }

            50% {
                transform: scale(0.75) rotate(-360deg);
            }

            100% {
                transform: scale(1) rotate(360deg);
            }
        }
    }
    
`

export default DefaultSpinnerContainer;