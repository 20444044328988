import styled from 'styled-components';

const AdminFormContainer = styled.div `
margin-right: 20px;
.textArea {
    width: 50%;
    height: 200px;
    color: var(--text-input);
    background-color: transparent !important;
    border: 2px solid #7f7f7f;
    font-size: 15px;
    
}

.input-copy-container{
    width: 50%;
}

.max-value-text {
    width: 50%;
}

.color-text {
    color: var(--text);
}

.input-link {
    width: 100%;
    color: var(--text);
    background-color: transparent !important;
    border: 2px solid #7f7f7f;
    font-size: 15px;
    height: 39px;
}
.input-link:focus {
    outline: none;
}

.input-readOne-password {
    color: var(--text-input);
    background-color: transparent !important;
    border: 2px solid #7f7f7f;
    font-size: 15px;
}

.span-position {
    font-size: 15px;
    display: inline-flex;
    width: 141px;
    justify-content: flex-end;
    margin-right: 5px;
}

.input-readOne-password:focus {
    outline: none;
}

.font-size-button {
    font-size: 20px;
}

.modal-text-input-choose {
    background-color: transparent !important;
    color: var(--text-input);
    width: 100px;
    height: 25px;
}

.modal-options {
    background-color: var(--select-option);
    color: white;
}

.button-copy {
    display: flex;
    margin-top: 10px;    
    width: 101%;
    justify-content: flex-end;
}

.button-container {
    display: flex;
    width: 50%;       
    justify-content: flex-end;
}



@media (max-width: 768px) {
    .input-copy-container{
        width: 80%;
    }
    .textArea {
        width: 90%;
        height: 200px;
    }
    .max-value-text {
        width: 91%;
    }

    .button-container {
        width: 90%;
    }

    .span-position {
        justify-content: flex-start;
    }
}

`


export default AdminFormContainer;