import axios from "axios";
import cookie from "react-cookies";


const setJWTToken = token => {
    if (token) {
        cookie.save("token", token, { path: "/" });
        axios.defaults.headers.common["Authorization"] = token;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
};

export default setJWTToken;