import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from '../components/ErrorPage/ErrorBoundary'
import { createHashHistory } from 'history'
import ErrorPage from "../components/ErrorPage/ErrorPage"




export const history = createHashHistory()

const SecuredRoute = ({ component: Component, ...otherProps }) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { validToken } = useSelector(state => state.security);
  const [isValidTokenSet, setIsValidTokenSet] = useState(false);

  useEffect(() => {

    if (isValidTokenSet) return;
    if (validToken) {
      setIsValidTokenSet(true);
      return;
    }

  }, [validToken, isValidTokenSet, dispatch, history])
 
  if (!isValidTokenSet)  return null;

  
  return (
    <Route
      {...otherProps}
      render={props =>
        validToken ? 
        (
          <ErrorBoundary history={history} FallbackComponent={ErrorPage}>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

export default SecuredRoute;