import cookie from "react-cookies";
import setJWTToken from "../securityUtils/setJWTToken";
import { SET_CURRENT_USER } from "./types";


export const logout = () => dispatch => {
    localStorage.removeItem("jwtToken");
    cookie.remove("token", { path: "/" });
    setJWTToken(false);
    dispatch({
      type: SET_CURRENT_USER,
      payload: {}
    });
};
