import styled from 'styled-components';

const InputWithMovingPlaceholderContainer = styled.div`
    position: relative;

    input {
        color: var(--login-input);
        background-color: transparent !important;

        &:focus ~ .input-floating-placeholder,
        &:not(:focus) ~ .input-is-valid {
            top: -1rem;
            left: 0rem;
            font-size: 0.75em;
            opacity: 0.75;
        }
    }

    .input-floating-placeholder {
        font-family: noah-bold;
        color: var(--login-text);
        position: absolute;
        left: 0.25rem;
        top: 0;
        pointer-events: none;
        transition: 0.25s ease-out;
    }

    .moving-placeholder-input-errors { 
        display: flex;
        flex-direction: column;
        color: red;
        font-size: 0.675em;
    }
`

export default InputWithMovingPlaceholderContainer;