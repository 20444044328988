import React from "react";
import "./ErrorPage.css";
import DateFunctions from '../../Functions/DateFunctions'

 class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>

        <div className="container">
          <div className="error-alert">
            <div className="row">
              <div className="col-sm-6 offset-sm-3 error404">Error 404</div>
            </div>
            <div className="d-flex justify-content-center align-items-center" >
            <label className='m-0 p-0' style={{ fontSize: '18px', lineHeight: '18px' }}>                    
                  </label>
                  <div className='align-items-center' style={{ fontSize: '25px', fontWeight: 'bold' }}>
                    &nbsp;{DateFunctions.formatDate(new Date())}
                    &nbsp;{DateFunctions.formatHour(new Date())}
                  </div>
            </div>
            <div className="row">
              <div className="col-sm-6 offset-sm-3 description404">
                {this.props.t('error')}
               
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }


}

export default ErrorPage;
