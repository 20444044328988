import moment from "moment";

class DateFunctions {
    formatDate = date => {
        if (date === '' || date === null) { return null; }
        const day = (moment(date).date() < 10) ? ('0' + moment(date).date()) : moment(date).date();
        const month = ((moment(date).month() + 1) < 10) ? ('0' + (moment(date).month() + 1)) : moment(date).month() + 1;
        const year = moment(date).year();

        return day + '-' + month + '-' + year;
    }

    formatHour = date => {
        if (date === '' || date === null) { return null; }
        const m = moment(date);
        const hour = m.hour() < 10 ? `0${m.hour() }` : m.hour();
        const minutes = m.minute() < 10 ? `0${m.minute() }` : m.minute();
        const seconds = m.second() < 10 ? `0${m.second() }` : m.second();

        return `${hour}:${minutes}:${seconds}`;
    }
}

export default new DateFunctions();