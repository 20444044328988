import React from 'react'
import InputWithMovingPlaceholderContainer from './InputWithMovingPlaceholderContainer';

const InputWithMovingPlaceholder = ({
    value,
    type,
    nameValue = '',
    placeholder = null,
    handleInputChange,
    required,
    inputClassName = '',
    containerClassName = '',
    placeholderClassName = '',
    errors = null,
    autoComplete = "off"
}) => {
    
    return (
        <InputWithMovingPlaceholderContainer className={`${containerClassName}`}>
            <input
                type={type}
                value={value}
                name={nameValue}
                onChange={e => handleInputChange(e)}
                required={required}
                className={inputClassName} 
                autoComplete={autoComplete}              
            />

            {placeholder && <span
                className={`input-floating-placeholder ${value.toString().length > 0 ? 'input-is-valid' : ''} ${placeholderClassName}`}
            >
                {placeholder}
            </span>}

            {errors && errors.length > 0 && <div className='moving-placeholder-input-errors'>
                {errors.map(error => <span key={error}>{error}</span>)}
            </div>}
        </InputWithMovingPlaceholderContainer>
    )
}

export default InputWithMovingPlaceholder