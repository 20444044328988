import styled from "styled-components";

const LoginPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;  

    > form {
        padding: 3rem 1rem;
        box-shadow: 0 0 1rem 0.125rem #7f7f7f;
        border: 1px solid #7f7f7f;
        border-radius: 0.5rem;
        background: rgba(255, 255, 255, 0.05);
    }

    .login-card {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        width: auto;
        height: auto;

        .login-card-input-container{
            margin: 1rem 0;
        }

        .login-card-input { 
            font-size: 1rem;
            width: 300px;
            border: none;
            border-bottom: 2px solid #7f7f7f;
            background: none;
            outline: none;
            padding: 0.125rem 0.25rem;
        }

        .disabled-input {
            display: none !important;
            opacity: 0 !important;
            height: 1px !important;
            width: 1px !important; 
        }

        .login-submit-btn {
            width: auto;
            margin: 0 auto;
        }
    }
`;

export default LoginPageContainer;