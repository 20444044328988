import React from "react";
import Navbar from "../components/Navbar";

const TimeOut = () => {
    return (
        <>
            <Navbar user={true}/>
            <div style={{position: "fixed", top: "80px"}}>
                <h3 style={{color: "var(--text)"}}>Upłynął czas odczytu.</h3>
            </div>
        </>
    )
}

export default TimeOut;