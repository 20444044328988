import React from "react";
import ErrorPage from "./ErrorPage";
//import ErrorsService from 'Services/ErrorsService';
import { connect } from "react-redux";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      allowError: true
    };
  }

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {

      this.setState({ hasError: false });
      if ((!this.state.allowError && !this.state.hasError) || action === "POP") {
        this.setState({
          allowError: true
        });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error, info) {

    if (this.state.allowError) {
      this.setState({
        hasError: true,
        allowError: false
      }, () => {
        if (error) {
          console.log(error)
        }
      });
    }
  }


  render() {
    if (this.state.hasError === true) {
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}

const mapStateToProps = state => ({
  user: state.security.user
});

export default connect(
  mapStateToProps
)(ErrorBoundary);