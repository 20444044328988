import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import AdminFormContainer from "./AdminFormContainer.styles";
import DayAction from "../actions/DayAction";
import Button from "../components/buttons/Button.styles";
import PassSenderAction from "../actions/PassSenderAction";
import DefaultSpinner from "../components/spinners";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from "react-qr-code";
import { setIsHash, setIsClear } from "../redux-actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import ModalPassword from "../Modal/ModalPassword";


const URL = `${window.location.origin}/#/link/`;

const Admin = () => {
    const dispatch = useDispatch();
    const [days, setDays] = useState([]);
    const [selectTime, setSelectTime] = useState(3);
    const [text, setText] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [show, setShow] = useState(false);
    const [hash, setHash] = useState('');
    const [readOne, setReadOne] = useState(false);
    const [readOnePassword, setReadOnePassword] = useState('');
    const [listBoolean, setListBoolean] = useState([{name: "Tak", value: true}, {name: "Nie", value: false}]);    
    const textAreaRef = useRef(null);
    const isClear = useSelector(state => state.admin.isClear);

    useEffect(() => {
        DayAction.getAllDays()
            .then(res => {
                if (res.status === 200) {
                    setDays(res.data);
                }
            })
    }, []);

    useEffect(() => {
        if (hash !== '') {
            dispatch(setIsHash(true));
        } else {
            dispatch(setIsHash(false));
        }
    }, [hash]);

    useEffect(() => {
        if (isClear) {
            setHash('');
            setSelectTime(3);
            setText('');
            setReadOne(false);
            setReadOnePassword('');
            dispatch(setIsClear(false));
        }
    }, [isClear]);

    const handleChange = (e) => {
        const { value } = e.target;
        setSelectTime(value);
    }

    const handleChangeListBoolean = (e) => {
        const { value } = e.target;
        setReadOne(value);
    }

    const handleChangeText = (e) => {
        const { value } = e.target;
        setText(value);
    }

    const save = () => {
        if (text && selectTime) {
            setIsFetching(true);
            const value = {
                value: text,
                time: selectTime,
                readOne: readOne,
                readOnePassword: readOnePassword
            }

            PassSenderAction.savePass(value)
                .then(res => {
                    if (res.status === 200) {
                        const value = res.data;
                        const newHash = URL + value;
                        setHash(newHash);
                        setIsFetching(false);
                    }
                })
                .catch((error) => {
                    setIsFetching(false);
                    toast.error('Nie udało się zapisać');
                })
        } else {
            toast.error('Uzupełnij pola');
        }

    }

    const copyToClipboard = (e) => {
        try {
            e.stopPropagation();
            textAreaRef.current.select();
            document.execCommand("copy");
            toast.success('Skopiowano do schowka');
        } catch (e) {
            toast.error('Nie udało się skopiować');
        }
    };

    const handleChangeReadPassword = (e) => {
        const { value } = e.target;
        setReadOnePassword(value.trimEnd());
    }


    return (
        <>
            <Navbar />
            <ToastContainer
                theme="dark"
                className="toast-margin"                
            />
            <div style={{ position: "fixed", top: "80px", width: "100%",  height: "calc(100% - 80px)", overflow: "auto"}}>
                <AdminFormContainer>
                    <ModalPassword
                        show={show}
                        setShow={setShow}
                        setText={setText}
                    />
                    {!hash ?
                        <>
                            <textarea className="textArea" value={text} maxLength={4000} onChange={(e) => handleChangeText(e)} />
                            <div className="max-value-text">
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <span style={{ fontSize: "12px", color: "red" }}>max 4000 znaków</span>
                                </div>
                            </div>

                            <div className="input-copy-container">

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div>                                        
                                        <span className="span-position color-text" >Czas do usuniecia: </span>                                        
                                        <select
                                            id="type"
                                            name="day"
                                            className="modal-text-input-choose"
                                            value={selectTime}
                                            onChange={(e) => handleChange(e)}
                                        >

                                            {days && days.map((day) => (
                                                <option key={day.id} className="modal-options" value={day.value} >{day.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                    <span className="color-text" style={{ fontSize: "15px" }}>Jednorazowy odczyt: </span>                                    
                                    <select
                                            id="type"
                                            name="day"
                                            className="modal-text-input-choose"
                                            style={{marginLeft: "6px"}}
                                            value={readOne}
                                            onChange={(e) => handleChangeListBoolean(e)}
                                        >

                                            {listBoolean && listBoolean.map((list) => (
                                                <option key={list.name} className="modal-options" value={list.value} >{list.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                    <span className="span-position color-text">Hasło do odczytu: </span>
                                    <input type="text" maxLength={16} className="input-readOne-password" value={readOnePassword} onChange={(e) => handleChangeReadPassword(e)}/> 
                                    </div>

                                </div>

                            </div>
                            <div className="button-container" style={{marginTop: "10px"}}>
                                <div style={{ display: "flex" }}>
                                    <Button style={{ marginRight: "10px" }} onClick={() => setShow(!show)}>Generuj</Button>
                                    {isFetching ? <DefaultSpinner
                                        className={'login-btn-spinner'}
                                    /> : <Button onClick={() => save()}>Zapisz</Button>}


                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="input-copy-container">
                                <input
                                    ref={textAreaRef}
                                    readOnly
                                    value={hash}
                                    className="input-link"
                                />
                                <div className="button-copy">
                                    <Button className="font-size-button" onClick={(e) => copyToClipboard(e)}>Kopiuj</Button>
                                </div>
                            </div>

                            <div style={{ marginTop: "20px" }}>
                                <div style={{ marginBottom: "5px" }}>
                                    <span className="color-text">Kod QR:</span>
                                </div>

                                <QRCode
                                    size={150}
                                    bgColor="white"
                                    fgColor="black"
                                    value={hash}
                                    style={{ border: "2px solid white" }}
                                />
                            </div>
                        </>}
                </AdminFormContainer>

            </div>
        </>
    )
}

export default Admin;