import React from 'react'
import DefaultButtonContainer from './DefaultButton.styles';
import DefaultSpinner from "../spinners/index";

const DefaultButton = ({
    value,
    type = '',
    onClickHandler,
    buttonClassName = '',
    spinnerClassName,
    isFetching = false,
    spinnerHeight,
    spinnerWidth
}) => {
    return (
        <DefaultButtonContainer
            type={type}
            onClick={isFetching ? null : onClickHandler}
            className={`${buttonClassName}`}
            isFetching={isFetching}
        >
            <span>{value}</span>
            {isFetching && <DefaultSpinner
                className={spinnerClassName}
                height={spinnerHeight}
                width={spinnerWidth}
            />}
        </DefaultButtonContainer>
    )
}

export default DefaultButton