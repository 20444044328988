import React, { useEffect, useState } from "react";
import NavbarContainer, { LoginButton } from "./style/NavbarContainer.styles";
import logoServ from "../../style/images/Serv4bCRM_logo_navbar.png";
import logoInfo from "../../style/images/info_projekt_navbar.png";
import { useHistory } from "react-router-dom";
import cookie from "react-cookies";
import setJWTToken from "../../securityUtils/setJWTToken";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_USER } from "../../actions/types";
import Button from "../../components/buttons/Button.styles";
import { setIsClear, setIsHash } from "../../redux-actions/adminActions";

const Navbar = ({
    user = false
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const jwtToken = cookie.load("token");
    const isHash = useSelector(state => state.admin.isHash);
    const company = useSelector(state => state.admin.company);
    const [isHome, setIsHome] = useState(false);

    useEffect(() => {
        if(window.location.hash === "#/"){
            dispatch(setIsHash(false));
            setIsHome(true);
        }
    }, [window.location.hash]);
    
    const goToLogin = () => {
        history.push('login');
    }

    const logout = () => {
        localStorage.removeItem("jwtToken");
        cookie.remove("token", { path: "/" });
        setJWTToken(false);
        dispatch({
            type: SET_CURRENT_USER,
            payload: {}
        });
    }

    const clearValue = () => {
        dispatch(setIsClear(true));
    }
    
    return (
        <NavbarContainer>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <img 
                src={company ? logoInfo : logoServ}
                alt="logo"
                // height="30px"
                // width="30px"
            />
            <span className="text-navbar">PassSender</span>
            </div>
            {!user ? !jwtToken ? 
            <LoginButton className="font-size-button" onClick={() => goToLogin()}>Login</LoginButton>
            :
            <div className="button-container">
            {isHash && <Button marginRight={10} className="font-size-button" onClick={() => clearValue()}>Dodaj nowy</Button>}
            {!isHome &&<LoginButton className="font-size-button" onClick={() => logout()}>Wyloguj</LoginButton>}
                </div> 
            
        : null}

        </NavbarContainer>
    )
}

export default Navbar;