import React, { useState } from "react";
import LoginPageContainer from './index.styles';
import logoServ from "../../style/images/Serv4bCRM_logo.png";
import logoInfo from "../../style/images/info-projekt-login.png";
import InputWithMovingPlaceholder from '../inputs/index';
import DefaultButton from "../buttons/index";
import LoginAction from "../../actions/LoginAction";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import setJWTToken from "../../securityUtils/setJWTToken"
import { SET_CURRENT_USER } from "../../actions/types"

const getErrors = (username, password) => {
    const usernameErrors = [];
  const passwordErrors = [];

  if (username.length === 0) {
    usernameErrors.push('Podaj login');
  }

  if (password.length === 0) {
    passwordErrors.push('Podaj hasło');
  }

  return {
    usernameErrors,
    passwordErrors
  };
}

const Login = (props) => {
    const dispatch = useDispatch();
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [errors, setErrors] = useState({ usernameErrors: [], passwordErrors: [] });
    const [isFetching, setIsFetching] = useState(false);
    const company = useSelector(state => state.admin.company);

    const onInputChange = (e) => {           
        e.preventDefault();
        const text = e.target.value;
        const name = e.target.name; 
        setCredentials({ ...credentials, [name]: text });
    }

    const handleSubmit  = async (e) => {      
        e.preventDefault();
        setIsFetching(true);
        const { username, password } = credentials;
        const User = {
            username,
            password
        }
        const errors = getErrors(username, password);

        if (errors.passwordErrors.length > 0 || errors.usernameErrors.length > 0) {
        setErrors({
            usernameErrors: errors.usernameErrors.map(error => `${error}`),
            passwordErrors: errors.passwordErrors.map(error => `${error}`)
        });

        setIsFetching(false);
        return;
        }
        try{
            const {data, status} = await  LoginAction.signIn(User);

            if(status && status === 200){
                setErrors({ usernameErrors: [], passwordErrors: [] });
                props.history.push("/admin");
                const token = data["token"];
                localStorage.setItem("jwtToken", token);
                const decoded = jwt_decode(token);
                dispatch({ type: SET_CURRENT_USER, payload: decoded });
                setJWTToken(token);
                setIsFetching(false);

            }
            
        }catch(error){
            if(error.response && error.response.status === 400){
                setErrors({ usernameErrors: ['Użytkownik o takim loginie nie istnieje'], passwordErrors: [] });
                setIsFetching(false);
                return;
            } 
            if(error.response && error.response.status === 500){
              setErrors({ usernameErrors: ['Nie można połączyć się z bazą danych'], passwordErrors: [] });
              setIsFetching(false);
              return;
          } 
            dispatch({ type: SET_CURRENT_USER, payload: {} });
            console.log(error)   
        }
        
    }
    return (
        <LoginPageContainer>
            <img src={company ? logoInfo : logoServ} alt="logo" height='300px' width='250px' />
            <form
                className="login-card"
                autoComplete='off'
                onSubmit={(e) => handleSubmit(e)}
            >
                <InputWithMovingPlaceholder
                    value={credentials.username}                    
                    handleInputChange={onInputChange}
                    placeholder={'Nazwa użytkownika'}
                    type={'text'}
                    inputClassName={'login-card-input'}
                    containerClassName={'login-card-input-container'}
                    nameValue={'username'}
                    autoComplete="off"
                    errors={errors.usernameErrors}
                />

                <InputWithMovingPlaceholder
                    value={credentials.password}                    
                    handleInputChange={onInputChange}
                    placeholder={'Hasło'}
                    type={'password'}
                    inputClassName={'login-card-input'}
                    containerClassName={'login-card-input-container'}
                    nameValue={'password'}
                    autoComplete="off"
                    errors={errors.passwordErrors}
                />
                <DefaultButton
                    value={'Login'}
                    type={'submit'}
                    buttonClassName={'login-submit-btn'}
                    onClickHandler={e => handleSubmit(e)}
                    spinnerClassName={'login-btn-spinner'}
                    isFetching={isFetching}
                />

            </form>
        </LoginPageContainer>
    )
    
}

export default Login;