import {    
    SET_IS_HASH,
    SET_IS_CLEAR,
    SET_COMPANY
  } from '../actions/types';
  
  const initialState = {
    isHash: false,
    isClear: false,
    company: false
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SET_IS_HASH:
        return {
          ...state,
          isHash: action.payload.value
        }
        case SET_IS_CLEAR:
        return {
          ...state,
          isClear: action.payload.value
        }
        case SET_COMPANY:
        return {
          ...state,
          company: action.payload.value
        }
        
      default:
        return state;
    }
  }